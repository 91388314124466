export const AUTH_CONSTANTS = {
  PATH_SIGNUP: '/auth/signup',
  PATH_SIGNIN: '/auth/signin',
  PATH_LOGOUT: '/auth/logout',
  PATH_RESET: '/auth/reset',
  PATH_PASSWORD: '/auth/password',
  PATH_VERIFY: '/auth/verify',
  PATH_CONFIRM: '/auth/confirm',
  PATH_REFRESH: '/auth/refresh',
  PATH_PROFILE: '/auth/profile',
};
